import React, {useCallback, useEffect} from 'react';
import {Drawer, IconButton as MuiIconButton, Stack, Box} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GraphSettings from "./GraphSettings";
import AirQualityGraphs from "./AirQualityGraphs";
import {styled} from "@mui/material/styles";
import {useMapContext} from "/@/context/mapContext";
import {useMapData} from "/@/context/useMapData";

const DrawerHeader = styled(Box)(({theme}) => ({
    backgroundColor: '#cee7ef',
    position: 'relative',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    visibility: 'visible',
    right: 0,
    left: 0,
    marginBottom: '30px',
    zIndex: 9999,
}));

const DrawerContent = styled(Box)(({theme}) => ({
    width: 'auto',
    height: '70vh',
    overflowY: 'scroll',
}));

const IconButton = styled(MuiIconButton)(({theme}) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.25),
    zIndex: 1300,
}));

const StackedContent = styled(Stack)(({theme}) => ({
    padding: theme.spacing(1),
}));

interface MobileDrawerProps {
    open: boolean;
    toggleDrawer: (open: boolean) => void;
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({ open, toggleDrawer }) => {
    const { chosenGraphSensor } = useMapContext();
    const { getSensorReadingsByDateRange } = useMapData();

    const {
        setChosenGraphSensor,
    } = useMapContext();

    // Effect to handle data refresh when drawer opens
    useEffect(() => {
        if (open && chosenGraphSensor) {
            getSensorReadingsByDateRange();
        }
    }, [open, chosenGraphSensor, getSensorReadingsByDateRange]);

    // Effect to clean up when drawer closes
    useEffect(() => {
        if (!open) {
            // Reset selected sensor when drawer closes
            setChosenGraphSensor(null);
        }
    }, [open]);

    const handleClose = useCallback(() => {
        toggleDrawer(false);
    }, [toggleDrawer]);

    return (
        <Drawer
            anchor={'bottom'}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    height: '70vh',
                    overflow: 'visible'
                }
            }}
        >
            <DrawerHeader>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DrawerHeader>
            <DrawerContent>
                <StackedContent>
                    <GraphSettings toggleDrawer={toggleDrawer} />
                    <AirQualityGraphs />
                </StackedContent>
            </DrawerContent>
        </Drawer>
    );
};
export default MobileDrawer;