import React, {Suspense, useEffect, useState} from "react";
import {Box, Grid, Skeleton, Stack, Typography,} from "@mui/material";
import {ShepherdTour} from "react-shepherd";

import Map from "../../components/map/Map";
import {MapProvider} from "/@/context/mapContext";
import {useTranslation} from "react-i18next";
import GraphSettings from "../../components/map/GraphSettings";
import {tourOptions} from "/@/components/shepherdTour/shepardTourOptions";
import {styled} from "@mui/material/styles";
import AirQualityGraphs from "/@/components/map/AirQualityGraphs";
import SwipeUpIcon from "@mui/icons-material/SwipeUp";
import MapTour from "/@/components/shepherdTour/MapTour";
import {useThemeContext} from "/@/context/themeContext";
import MobileDrawer from "/@/components/map/MobileDrawer";
import {useMapData} from "/@/context/useMapData";

type Props = {};

export interface LocationCoords {
    lat: number | undefined;
    lng: number | undefined;
}


const PageGridWrapper = styled(Grid)(({theme}) => ({
    display: 'flex',
    maxHeight: '100vh',
    [theme.breakpoints.down('md')]: {
        maxHeight: 'calc(100vh - 90px)',
    },
}));

const MapArea = styled(Box)(({theme}) => ({
    flexGrow: 1,
    position: 'relative',
    padding: '40px',
    marginBottom: undefined,
    marginTop: undefined,
    [theme.breakpoints.down('md')]: {
        padding: '5px',
        marginBottom: '110px',
        marginTop: '10px',
    },
}));

const LeftSide = styled(Grid)(({theme}) => ({
    height: '100vh',
    flexDirection: 'column',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        height: 'calc(100vh - 100px)',
    },
}));

const Sidebar = styled(Grid)(({theme}) => ({
    height: '100vh',
    overflowY: 'auto',
    background: 'linear-gradient(to bottom, #d6f5ff, 50%, #cee7ef 100%)',
    borderRadius: '5px 0px 0px 5px',
    position: 'relative',
    bottom: 0,
    zIndex: 2,
    padding: 20,
    display: 'none',
    [theme.breakpoints.up('md')]: { // This shows the sidebar for non-mobile devices
        display: 'block',
    },
}));

const PullUpTab = styled('div')(({theme}) => ({
    position: 'fixed',
    bottom: '90px',
    left: 0,
    right: 0,
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid',
    borderColor: 'grey.300',
    backgroundColor: '#cee7ef',
    zIndex: 100,
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: { // Hide pull-up tab on non-mobile devices
        display: 'none',
    },
}));

const MapTourGrid = styled(Grid)(({theme}) => ({
    display: 'flex',
    position: 'absolute',
    right: '39.33%',
    zIndex: 9999,
    [theme.breakpoints.down('md')]: { // Hide pull-up tab on non-mobile devices
        right: 10,
        top: 20
    },
}))

function Page(props: Props) {
    const {isMobile} = useThemeContext()
    const {t, i18n} = useTranslation();
    const {sensorData, fetchAirData} = useMapData();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = (open: boolean) => {
        setDrawerOpen(open);
    };

    useEffect(() => {
        fetchAirData(false);
    }, []);

    useEffect(() => {
        // Check if the app is running on a mobile device
        const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);

        if (isMobileDevice) {
            // Delay the scroll to give the page time to load
            setTimeout(() => {
                // Scroll 1 pixel to trigger the address bar to hide
                window.scrollTo(0, 1);
            }, 1000); // Adjust the delay as needed
        }
    }, []);

    return (
        <Suspense fallback={<Skeleton variant="rectangular" width="100%" height="100%"/>}>
            <MapProvider>
                <ShepherdTour steps={[]} tourOptions={tourOptions}>
                    <PageGridWrapper container>
                        <LeftSide item xs={12} md={7}>
                            <MapTourGrid item xs={12} lg={7}>
                                <MapTour handleDrawerOpen={toggleDrawer} handleClose={() => {
                                }}/>
                            </MapTourGrid>
                            <MapArea>
                                <Map
                                    toggleDrawer={toggleDrawer}
                                    sensorData={sensorData}
                                    isMapPage={true}
                                    setSelectedSensor={() => {
                                    }}
                                    selectedSensorId={""}
                                />
                            </MapArea>

                            <PullUpTab onClick={() => toggleDrawer(true)}>
                                <SwipeUpIcon/>
                                <Typography variant="body1">{t('swiper_button')}</Typography>
                            </PullUpTab>
                            {isMobile && (
                                <MobileDrawer open={drawerOpen} toggleDrawer={toggleDrawer}/>
                            )}
                        </LeftSide>
                        <Sidebar item md={5}>
                            <Stack>
                                <GraphSettings toggleDrawer={toggleDrawer}/>
                                <AirQualityGraphs/>
                            </Stack>
                        </Sidebar>
                    </PageGridWrapper>
                </ShepherdTour>
            </MapProvider>
        </Suspense>
    );
}

export default Page;
