import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Card,
    CardActionArea,
    CardMedia,
    Box,
    useMediaQuery, useTheme
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useMapContext } from '/@/context/mapContext';
import defaultMapImage from "/@/assets/graph-icons/default-map.jpg";
import hackneyNo2Image from "/@/assets/graph-icons/hackney-no2-air-quality-heatmap.jpg";
import hackneyPm10Image from "/@/assets/graph-icons/hackney-pm10.jpg";
import towerHamletsCanalImage from "/@/assets/graph-icons/tower-hamlets-canal-boat-smoke-complaints.jpg";
import towerHamletsNo2Image from "/@/assets/graph-icons/tower-hamlets-no2-diffusion-tubes.jpg";
import newhamNo2Image from "/@/assets/graph-icons/newham-no2-2023.png";
import newhamDiffusionImage from "/@/assets/graph-icons/newham-no2-diffusion-tubes.jpg";
import {useWidth} from "/@/utils/useWidth";
import {useTranslation} from "react-i18next";


// Placeholder data structure for the interactive resources
const interactiveResources = [
    {
        title: "Default Map",
        iframeSrc: null,
        image: defaultMapImage
    },
    {
        title: "Hackney - PM10 Air Quality Heatmap",
        iframeSrc: "https://map2.hackney.gov.uk/maps/air-quality-pm10/index.html",
        image: hackneyPm10Image
    },
    {
        title: "Newham - NO2 Air Quality monitoring sites (2023)",
        iframeSrc: "https://lbnewham.maps.arcgis.com/apps/mapviewer/index.html?webmap=e1318b636249496f9ade542c03a83180",
        image: newhamNo2Image
    },
    {
        title: "Newham - NO2 Diffusion Tubes",
        iframeSrc: "https://www.google.com/maps/d/u/0/embed?mid=1AuPJGKDbFCK3Z9aMv9x966l02fDLFuAN&ehbc=2E312F&ll=51.527288411593716%2C0.025188871000003665&z=12",
        image: newhamDiffusionImage

    },
    {
        title: "Hackney - NO2 Air quality monitoring sites (2015-2021)",
        iframeSrc: "https://map2.hackney.gov.uk/maps/air-quality-monitoring/embed.html",
        image: hackneyPm10Image
    },
    {
        title: "Hackney - NO2 Air Quality Heatmap",
        iframeSrc: "https://map2.hackney.gov.uk/maps/air-quality-no2/index.html",
        image: hackneyNo2Image
    },
    {
        title: "Tower Hamlets - NO2 Diffusion Tubes (2019)",
        iframeSrc: "https://towerhamlets.maps.arcgis.com/apps/webappviewer/index.html?id=8837ac6da6354de981020484d6ee6003",
        image: towerHamletsNo2Image

    },
    {
        title: "Tower Hamlets - Canal Boat Smoke Complaints",
        iframeSrc: "https://towerhamlets.maps.arcgis.com/apps/instant/basic/index.html?appid=4a145ac76fd94281918aa95a4813d66f",
        image: towerHamletsCanalImage
    },

];
interface CarouselProps {
    toggleDrawer: (open: boolean) => void;
}

const InteractiveMapCarousel: React.FC<CarouselProps> = ({toggleDrawer}) => {
    const { selectedIFrameMap, setSelectedIFrameMap } = useMapContext();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const width = useWidth();
    const [numberSlides, setNumberSlides] = useState<number>(2);
    const {t} = useTranslation();

    useEffect(() => {
        switch (width) {
            case "md":
                setNumberSlides(2);
                break;
            case "lg":
                setNumberSlides(3);
                break;
            case "xl":
                setNumberSlides(4);
                break;
        }
    }, [width]);

    const handleAccordionChange = (panel: boolean) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion expanded={expanded} onChange={handleAccordionChange(true)} sx={{ background: 'white' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{t('interactive_map_selection')}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: 'none' }}>
                <Swiper modules={[Navigation, Pagination]} navigation pagination={{ clickable: true }} spaceBetween={10} slidesPerView={numberSlides}>
                    {interactiveResources.map((resource, index) => (
                        <SwiperSlide  key={index}>
                            <Card
                                onClick={() => {
                                    setSelectedIFrameMap(resource.iframeSrc)
                                    if(isMobile) toggleDrawer(false);
                                }
                            }
                                sx={{

                                    height: '250px',
                                    background: 'none',
                                    boxShadow: 'none',
                                    border: selectedIFrameMap === resource.iframeSrc ? '0.5px solid black' : '0.5px solid transparent',
                                }}
                            >
                                <CardActionArea onClick={() => setSelectedIFrameMap(resource.iframeSrc)} sx={{height: '100%'}}>
                                    <Box sx={{height: '150px'}}>
                                    <CardMedia
                                        component="img"
                                        height="150px"
                                        width="150px"
                                        image={resource.image}
                                        alt={'Image of ' + resource.title}
                                        sx={{ objectFit: 'cover'}}
                                    />
                                    </Box>
                                    <Box sx={{ p: 2, height: '100px' }}>
                                        <Typography gutterBottom variant="body2">
                                            {resource.title}
                                        </Typography>
                                    </Box>
                                </CardActionArea>
                            </Card>
                        </SwiperSlide>
                    ))}
                    <Box sx={{ pt: 2 }} className="swiper-custom-pagination" />
                </Swiper>
            </AccordionDetails>
        </Accordion>
    );
};

export default InteractiveMapCarousel;
