import React, {useEffect, useState} from 'react';
import {Box, Chip, CircularProgress, Grid} from '@mui/material';
import {useMapContext} from "/@/context/mapContext";
import {useThemeContext} from "/@/context/themeContext";
import {useMapData} from "/@/context/useMapData";
import {PollutantType} from "/@/constants/chartContants";
import AirQualityChart from "/@/components/graphs/AirQualityChart";

const AirQualityGraphs = () => {
    // 1. Move all hooks to the top
    const {isMobile} = useThemeContext();
    const {
        airQualityData,
        selectedMobilePollutant,
        setSelectedMobilePollutant
    } = useMapContext();

    const {getAverageReadingsByBorough, getSensorReadingsByDateRange, isLoadingSensorData} = useMapData();
    const [isLoadingChartData, setIsLoadingChartData] = useState<boolean>(true);

    // Effect to handle initial data load
    useEffect(() => {

        // IF THERE IS ALREADY DATA, SKIP
        if (airQualityData.pm25.length > 0 || airQualityData.pm10.length > 0 || airQualityData.no2.length > 0) {
            setIsLoadingChartData(false);
            return;
        }

        const fetchInitialData = async () => {
            await getAverageReadingsByBorough();
            setIsLoadingChartData(false);
        }
        fetchInitialData();
    }, [getAverageReadingsByBorough]);

    // // Effect to handle data updates based on parameter changes
    // useEffect(() => {
    //     const fetchData = async () => {
    //         setIsLoadingChartData(true);
    //         try {
    //             if (chosenGraphSensor) {
    //                 await getSensorReadingsByDateRange();
    //             } else {
    //                 await getAverageReadingsByBorough();
    //             }
    //         } finally {
    //             setIsLoadingChartData(false);
    //         }
    //     };
    //     fetchData();
    // }, [
    //     chosenBorough,
    //     chosenGraphSensor,
    //     chosenDateRange,
    //     getSensorReadingsByDateRange,
    //     getAverageReadingsByBorough
    // ]);

    // 3. Component functions
    const renderDesktopGraphs = () => {
        return (
            <>
                <AirQualityChart pollutant="PM2.5" airQualityData={airQualityData} isMapPage={true}/>
                <AirQualityChart pollutant="PM10" airQualityData={airQualityData} isMapPage={true}/>
                <AirQualityChart pollutant="NO2" airQualityData={airQualityData} isMapPage={true}/>
            </>
        );
    };

    const renderMobileGraphs = () => {
        return (
            <>
                <Box display="flex" justifyContent="center" flexWrap="wrap" gap={1}>
                    {(['PM2.5', 'PM10', 'NO2'] as PollutantType[]).map((pollutant) => (
                        <Chip
                            key={pollutant}
                            label={pollutant}
                            onClick={() => setSelectedMobilePollutant(pollutant)}
                            color={selectedMobilePollutant === pollutant ? 'success' : 'default'}
                        />
                    ))}
                </Box>
                <AirQualityChart
                    pollutant={selectedMobilePollutant}
                    airQualityData={airQualityData}
                    isMapPage={true}
                />
            </>
        );
    };

    // 4. Conditional rendering
    if (isLoadingSensorData || isLoadingChartData) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="200px">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Grid container>
            {isMobile ? renderMobileGraphs() : renderDesktopGraphs()}
        </Grid>
    );
};

export default AirQualityGraphs;